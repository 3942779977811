import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { FILTER_ICON_MAP_TYPE, PaymentMethod, TxnStatus } from 'types/transactions';

export const txnStatusDetails = {
  [TxnStatus.IN_PROCESS]: {
    label: 'In Process',
    style: { backgroundColor: COLORS.ORANGE_TERTIARY, color: COLORS.ORANGE_SECONDARY },
  },
  [TxnStatus.PENDING]: {
    label: 'Approval Pending',
    style: { backgroundColor: COLORS.ORANGE_TERTIARY, color: COLORS.ORANGE_SECONDARY },
  },
  [TxnStatus.SUCCEEDED]: {
    label: 'Successful',
    style: { backgroundColor: COLORS.GREEN_TERTIARY, color: COLORS.GREEN_PRIMARY },
  },
  [TxnStatus.FAILED]: {
    label: 'Failed',
    style: { backgroundColor: COLORS.RED_TERTIARY, color: COLORS.RED_PRIMARY },
  },
  [TxnStatus.CREATED]: {
    label: 'In Process',
    style: { backgroundColor: COLORS.ORANGE_TERTIARY, color: COLORS.ORANGE_SECONDARY },
  },
  [TxnStatus.VALIDATED]: {
    label: 'Approval Pending',
    style: { backgroundColor: COLORS.ORANGE_TERTIARY, color: COLORS.ORANGE_SECONDARY },
  },
  [TxnStatus.VALIDATION_FAILED]: {
    label: 'In Process',
    style: { backgroundColor: COLORS.ORANGE_TERTIARY, color: COLORS.ORANGE_SECONDARY },
  },
  [TxnStatus.TNC_PENDING]: {
    label: '',
    style: { backgroundColor: '', color: '' },
  },
  [TxnStatus.PARTIAL_SUCCESS]: {
    label: '',
    style: { backgroundColor: '', color: '' },
  },
  [TxnStatus.IN_REVIEW]: {
    label: 'In Review',
    style: { backgroundColor: COLORS.ORANGE_TERTIARY, color: COLORS.ORANGE_SECONDARY },
  },
  [TxnStatus.FAILED_DUE_TO_COMPLIANCE]: {
    label: 'Compliance Failed',
    style: { backgroundColor: COLORS.RED_TERTIARY, color: COLORS.RED_PRIMARY },
  },
  [TxnStatus.DELAYED]: {
    label: 'Delayed',
    style: { backgroundColor: COLORS.ORANGE_TERTIARY, color: COLORS.ORANGE_PRIMARY },
  },
  [TxnStatus.RFI_REQUESTED]: {
    label: 'RFI Requested',
    style: { backgroundColor: COLORS.ORANGE_TERTIARY, color: COLORS.ORANGE_SECONDARY },
  },
};

export const TRANSACTION_STATUS_PROPERTIES = {
  [TxnStatus.IN_PROCESS]: { label: 'In Process', className: 'tw-bg-ORANGE_TERTIARY tw-text-ORANGE_PRIMARY' },
  [TxnStatus.PENDING]: { label: 'Approval Pending', className: 'tw-bg-ORANGE_TERTIARY tw-text-ORANGE_PRIMARY' },
  [TxnStatus.SUCCEEDED]: { label: 'Successful', className: 'tw-bg-GREEN_TERTIARY tw-text-GREEN_PRIMARY' },
  [TxnStatus.FAILED]: { label: 'Failed', className: 'tw-bg-RED_TERTIARY tw-text-RED_PRIMARY' },
  [TxnStatus.CREATED]: { label: 'In Process', className: 'tw-bg-ORANGE_TERTIARY tw-text-ORANGE_PRIMARY' },
  [TxnStatus.VALIDATED]: { label: 'Approval Pending', className: 'tw-bg-ORANGE_TERTIARY tw-text-ORANGE_PRIMARY' },
  [TxnStatus.VALIDATION_FAILED]: { label: 'In Process', className: 'tw-bg-ORANGE_TERTIARY tw-text-ORANGE_PRIMARY' },
  [TxnStatus.TNC_PENDING]: { label: '', className: '' },
  [TxnStatus.PARTIAL_SUCCESS]: { label: 'SUCCESSFUL', className: 'tw-bg-SECONDARY tw-text-ZAMP_PRIMARY' },
  [TxnStatus.IN_REVIEW]: { label: 'In Review', className: 'tw-bg-ORANGE_TERTIARY tw-text-ORANGE_PRIMARY' },
  [TxnStatus.FAILED_DUE_TO_COMPLIANCE]: {
    label: 'Compliance Failed',
    className: 'tw-bg-RED_TERTIARY tw-text-RED_PRIMARY',
  },
  [TxnStatus.DELAYED]: {
    label: 'Delayed',
    className: 'tw-bg-ORANGE_TERTIARY tw-text-ORANGE_PRIMARY',
  },
  [TxnStatus.RFI_REQUESTED]: { label: 'RFI Requested', className: 'tw-bg-ORANGE_TERTIARY tw-text-ORANGE_PRIMARY' },
};

export const txnFilters = [
  { label: 'All', value: 'all' },
  { label: 'In process', value: 'initiated' },
  { label: 'Successful', value: 'succeeded' },
  { label: 'Failed', value: 'failed' },
];

export const TRANSFER_METHODS = [
  { label: 'All', value: [] },
  { label: 'Payments', value: ['CRYPTO', 'FIAT'] },
  { label: 'Treasury Transfer', value: ['Treasury'] },
];

export const mapPaymentMethodNames: { [index: string]: string } = {
  [PaymentMethod.CRYPTO]: 'Digital Asset',
  [PaymentMethod.BANK_TRANSFER]: 'Bank Transfer',
  [PaymentMethod.FIAT]: 'Bank Transfer',
};

export const transactionStatus = {
  SUCCESS: 'succeeded',
  ACTIVE: 'active',
  PENDING: 'initiated',
  FAILED: 'failed',
  RFI_REQUESTED: 'rfi_requested',
};

export const transactionType = {
  DEBIT: 'debit',
  CREDIT: 'credit',
};

export const TxnTypes = [
  { label: 'Single Transfers', value: 'single' },
  { label: 'Bulk Transfers', value: 'bulk' },
];

export const STATUS_SUCCESS = 'SUCCESSFUL';
export const STATUS_PENDING = 'IN PROCESS';
export const STATUS_FAILED = 'FAILED';

export const tableHeaderStyle = 'tw-px-6 tw-py-3 tw-text-TEXT_SECONDARY f-10-600';

export enum FILTER_TYPES {
  DATE_RANGE = 'date_range',
  CURRENCIES = 'currencies',
  STATUSES = 'statuses',
  TRANSFER_METHODS = 'transfer_methods',
  BENEFICIARIES = 'beneficiaries',
  CARDS = 'cards',
}

export const FILTER_LABEL_MAP: Record<string, string> = {
  [FILTER_TYPES.DATE_RANGE]: 'Date range',
  [FILTER_TYPES.CURRENCIES]: 'Currency',
  [FILTER_TYPES.STATUSES]: 'Status',
  [FILTER_TYPES.TRANSFER_METHODS]: 'Type',
  [FILTER_TYPES.BENEFICIARIES]: 'Recipient',
  [FILTER_TYPES.CARDS]: 'Card',
};

export const FILTER_ICON_MAP: Record<string, FILTER_ICON_MAP_TYPE> = {
  [FILTER_TYPES.DATE_RANGE]: {
    id: 'calendar',
    iconCategory: ICON_SPRITE_TYPES.TIME,
  },
  [FILTER_TYPES.CURRENCIES]: {
    id: 'currency-dollar-circle',
    iconCategory: ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE,
  },
  [FILTER_TYPES.STATUSES]: {
    id: 'annotation-check',
    iconCategory: ICON_SPRITE_TYPES.COMMUNICATION,
  },
  [FILTER_TYPES.TRANSFER_METHODS]: {
    id: 'command',
    iconCategory: ICON_SPRITE_TYPES.EDITOR,
  },
  [FILTER_TYPES.BENEFICIARIES]: {
    id: 'user-01',
    iconCategory: ICON_SPRITE_TYPES.USERS,
  },
  [FILTER_TYPES.CARDS]: {
    id: 'credit-card-02',
    iconCategory: ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE,
  },
};
